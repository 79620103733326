import React from 'react';


import * as s from './about-section.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';


const AboutSection = () => {



    return (
        <section className={`${s.about} ${s_wrapper.apply} ${s_wrapper.apply_low_top_height}`}>

            <h2>O ClickLease</h2>
            <p className={`${s_text.subtitle} ${s.subtitle}`}>
                Kto stoi za sukcesem naszych Klientów? Dowiedz się więcej o ClickLease!
            </p>

            <article>
            <div>
                <p>
	                ClickLease to firma, w której znajdziesz  specjalistów łączących zamiłowanie do motoryzacji z
	                doświadczeniem w branży finansowej, głównie w leasingu ale również w wynajmie długoterminowym czy kredycie samochodowym.
	                Dzięki naszemu doświadczeniu jesteśmy w stanie oferować naszym klientom najkorzystniejsze rozwiązania dostępne na rynku.
                </p>

				<h3>
					Naszym Klientom gwarantujemy:
				</h3>
                <ul className={`${s_text.checkmark_list}`}>
                    <li>
	                    najkorzystniejsze i najniżej oprocentowane  leasingi  i kredyty  dostępne na rynku
                    </li>
                    <li>
	                    profesjonalizm, rzetelność, skuteczne i szybkie działanie  poparte wieloletnim doświadczeniem w branży finansowej
                    </li>
                    <li>
	                    kompleksowość usług tzn. załatwiamy wszystkie  formalności od A do Z
                    </li>
                    <li>
	                    stały  bezpośredni kontakt, wsparcie i pomoc
                    </li>
                    <li>
	                    bezpieczeństwo transakcji – poufność, przestrzeganie tajemnicy bankowej oraz ochrony danych osobowych
                    </li>
                    <li>
	                    satysfakcję i zadowolenie z doboru zgodnej z ich oczekiwaniami oferty
                    </li>
                </ul>
				<br/>
	            <b>
		            Najważniejszym i najcenniejszym kapitałem naszej  Firmy jest Państwa zaufanie. Dlatego zawsze działamy
		            zgodnie z etyką zawodu, profesjonalnie i kompleksowo. Powracający, zadowolony klient to
		            nasz cel, to nasza codzienność. Serdecznie zapraszamy do współpracy !
	            </b>

            </div>
            <div>
                <h3>Co nas wyróżnia?</h3>
                <ul className={`${s_text.checkmark_list}`}>
	                <li>
		                <b>Kompleksowa obsługa Klientów w zakresie zawierania umów leasingu</b><br/>
		                Nasi Klienci nie tracą czasu na zbędne formalności – my wypełniamy za nich wszelkie formularze,
		                wnioski, pilnujemy terminów rozpatrzenia wniosków, rejestrujemy samochody, jesteśmy w stałym
		                kontakcie z Klientami od momentu pojawienia się pomysłu na leasing aż do zakończenia umowy.
	                </li>
	                <li>
		                <b>Codzienna aktualizacja i bieżąca znajomość wszystkich ofert leasingowych na rynku</b><br/>
		                Jeżeli pojawia się nowa oferta lub promocja, to wiemy o nich pierwsi. Dzięki temu nasi
		                Klienci unikają długotrwałego i frustrującego procesu poszukiwania informacji.
	                </li>
	                <li>
		                <b>Skuteczność w załatwianiu Państwa wniosków</b><br/>
		                Dzięki bardzo dobrej znajomości kryteriów, jakie stosują firmy leasingowe przy rozpatrywaniu
		                wniosków, potrafimy bardzo skutecznie dobierać oferty, nawet przy najbardziej nietypowych
		                sytuacjach biznesowych i przedmiotach leasingu.
	                </li>
	                <li>
		                <b>Skuteczność w załatwianiu Państwa wniosków</b><br/>
		                Dzięki bardzo dobrej znajomości kryteriów, jakie stosują firmy leasingowe przy rozpatrywaniu
		                wniosków, potrafimy bardzo skutecznie dobierać oferty, nawet przy najbardziej nietypowych
		                sytuacjach biznesowych i przedmiotach leasingu.
	                </li>
	                <li>
		                <b>Profesjonalne i rzetelne doradztwo w doborze najbardziej optymalnej oferty – także w
			                kontekście korzyści podatkowych i rachunkowych</b>
	                </li>
	                <li>
		                <b>Najkorzystniejsze finansowo oferty leasingu w Polsce</b><br/>
		                Dzięki centralnie wynegocjowanym umowom z firmami leasingowymi mamy dostęp
		                do najlepszych warunków leasingu na rynku!
	                </li>
	                <li>
		                <b>Bezpieczeństwo transakcji</b>
	                </li>
                </ul>
            </div>
            </article>

        </section>
    )
};

export default AboutSection;
