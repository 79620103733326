// extracted by mini-css-extract-plugin
export var active = "kontakt-module--active--2f998";
export var address = "kontakt-module--address--fd04b";
export var bottom_wrapper = "kontakt-module--bottom_wrapper--bba0f";
export var contact_info_section = "kontakt-module--contact_info_section--49123";
export var content_wrapper = "kontakt-module--content_wrapper--af21a";
export var form = "kontakt-module--form--ba21a";
export var form_section = "kontakt-module--form_section--d2c55";
export var inside = "kontakt-module--inside--94e25";
export var item = "kontakt-module--item--eb921";
export var left_wrapper = "kontakt-module--left_wrapper--54905";
export var less_important = "kontakt-module--less_important--3ebab";
export var loc_nav = "kontakt-module--loc_nav--1eacc";
export var map = "kontakt-module--map--509fb";
export var more_clicklease_section = "kontakt-module--more_clicklease_section--87743";
export var results_wrapper = "kontakt-module--results_wrapper--85cbc";
export var right_wrapper = "kontakt-module--right_wrapper--20d94";
export var subtitle = "kontakt-module--subtitle--2afb3";
export var svg_map = "kontakt-module--svg_map--ee484";
export var wrapper = "kontakt-module--wrapper--800ba";